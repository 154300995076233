"use client";

import { Box, Button, Stack, TextField } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ContentCopy from "@mui/icons-material/ContentCopy";
import { useChat } from "ai/react";
import ContentLoader from "react-content-loader";

const typingSpeed = 5; // Speed of typing can be adjusted here

interface CopyButtonProps {
  textToCopy: string;
}
function CopyButton(props: CopyButtonProps) {
  const [showCopyConfirm, setShowCopyConfirm] = useState(false);
  const { t } = useTranslation();

  const copyText = () => {
    try {
      navigator.clipboard.writeText(props.textToCopy);
      setShowCopyConfirm(true);
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };

  return (
    <>
      <Tooltip title="Copy">
        <IconButton onClick={copyText}>
          <ContentCopy />
        </IconButton>
      </Tooltip>
      <Snackbar
        open={showCopyConfirm}
        autoHideDuration={6000}
        onClose={() => setShowCopyConfirm(false)}
        message={t("text-copied-to-clipboard")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </>
  );
}
function TypingMessage({
  text,
  disableAnimation = false,
}: {
  text: string;
  disableAnimation?: boolean;
}) {
  const [displayText, setDisplayText] = useState("");
  const index = useRef(0);
  const displayTextRef = useRef("");

  useEffect(() => {
    if (disableAnimation) {
      setDisplayText(text);
    } else {
      const timer = setInterval(() => {
        if (index.current < text.length) {
          const char = text.charAt(index.current);
          if (char === "\n") {
            displayTextRef.current += "<br>";
          } else {
            displayTextRef.current += char;
          }
          setDisplayText(() => displayTextRef.current);
          index.current += 1;
        } else {
          clearInterval(timer);
        }
      }, typingSpeed);

      return () => clearInterval(timer);
    }
  }, [text, disableAnimation]);

  return (
    <span
      className="typing"
      dangerouslySetInnerHTML={{ __html: displayText }}
    />
  );
}

export default function ArtificialIntelligence() {
  const { t, i18n } = useTranslation();

  const { messages, input, handleSubmit, handleInputChange, isLoading } =
    useChat({
      api: "api/ai/chat",
      streamProtocol: "text",
      initialMessages: [
        {
          id: "1",
          role: "assistant",
          content: t(
            "hello-how-can-i-assist-you-today-i-am-rudaw-ai-assistant-i-can-help-you-with-translation-and-writing-in-the-rudaw-media-network-style"
          ),
        },
      ],
    });

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const direction = i18n.dir();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <Box
      sx={[
        (theme) =>
          theme.applyStyles("dark", {
            backgroundColor: "#181B1E",
          }),
      ]}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bgcolor="#edeff2"
    >
      <Stack
        direction="column"
        width="100%"
        height="82vh"
        border="1px solid #9A9498"
        p={2}
        spacing={3}
      >
        <Stack
          direction="column"
          spacing={5}
          flexGrow={1}
          overflow="auto"
          maxHeight="100%"
          p={2}
        >
          {messages.map((message) => (
            <Box
              key={message.id}
              display="flex"
              justifyContent={
                direction === "ltr"
                  ? message.role === "assistant"
                    ? "flex-start"
                    : "flex-end"
                  : message.role === "user"
                  ? "flex-end"
                  : "flex-start"
              }
            >
              <Box
                bgcolor={
                  message.role === "assistant"
                    ? "primary.main"
                    : "secondary.main"
                }
                color="white"
                borderRadius={16}
                p={3}
                sx={{ maxWidth: "fit-content", width: "auto" }}
              >
                <TypingMessage
                  key={message.id}
                  text={message.content}
                  disableAnimation={message.role === "user"}
                />
                {(message.role === "assistant" ||
                  message.role === "system") && (
                  <CopyButton textToCopy={message.content} />
                )}
              </Box>
            </Box>
          ))}
          {isLoading ? (
            <ContentLoader
              viewBox="30 0 950 500"
              foregroundColor="#2C3E50" // Custom foreground (loading animation) color
            >
              <rect x="56" y="12" rx="5" ry="5" width="1000" height="10" />
              <rect x="56" y="29" rx="5" ry="5" width="250" height="10" />
            </ContentLoader>
          ) : (
            ""
          )}
          <div ref={messagesEndRef} />
        </Stack>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Stack direction="row" gap={2} width="100%" justifyContent="center">
            <TextField
              label={t("message")}
              fullWidth
              autoComplete="off"
              value={input}
              multiline
              minRows={2}
              maxRows={5}
              onChange={handleInputChange}
              onKeyPress={(event) => {
                if (event.key === "Enter" && !event.shiftKey) {
                  event.preventDefault();
                  handleSubmit(event);
                }
              }}
              disabled={isLoading}
              autoFocus
            />
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              sx={{ height: "50px" }}
            >
              {isLoading ? t("sending") : t("send")}
            </Button>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
}

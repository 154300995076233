"use client";

import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Select,
  MenuItem,
  Slider,
  Button,
  FormControl,
  InputLabel,
  Stack,
  CircularProgress,
  Alert,
  Snackbar,
  SelectChangeEvent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DownloadIcon from "@mui/icons-material/Download";
import WaveSurfer from "wavesurfer.js";
import { useTranslation } from "react-i18next";
import { apiFetch } from "./../../utils/api";

// Types
type Voice = {
  id: string;
  name: string;
};

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 800,
  margin: "0 auto",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  "& .MuiSlider-thumb": {
    "&:hover, &.Mui-focusVisible": {
      boxShadow: `0px 0px 0px 8px ${theme.palette.primary.main}20`,
    },
  },
}));

const WaveformContainer = styled(Box)({
  width: "100%",
  height: "100px",
  marginTop: "20px",
  marginBottom: "20px",
});

export default function TextToSpeech() {
  const { t } = useTranslation();

  const [text, setText] = useState<string>("");
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [speed, setSpeed] = useState<number>(1);
  const [selectedVoice, setSelectedVoice] = useState<string>("zana");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const waveformRef = useRef<HTMLDivElement | null>(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);

  const voices: Voice[] = [{ id: "zana", name: "Zana Kayani" }];

  useEffect(() => {
    if (waveformRef.current && !wavesurferRef.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "#4a90e2",
        progressColor: "#2196f3",
        cursorColor: "#2196f3",
        height: 80,
        normalize: true,
        interact: true,
      });

      wavesurferRef.current.on("finish", () => {
        setIsPlaying(false);
      });

      wavesurferRef.current.on("ready", () => {
        if (audioRef.current) {
          audioRef.current.playbackRate = speed;
        }
      });

      return () => {
        if (wavesurferRef.current) {
          wavesurferRef.current.destroy();
        }
      };
    }
  }, []);

  const handleTextToSpeech = async () => {
    if (!text.trim()) {
      setError(t("please-enter-some-text"));
      return;
    }
    setIsLoading(true);
    setError(null);
    try {
      const response = await apiFetch("spelling/create-suggestion", {
        method: "POST",
        body: JSON.stringify({
          text,
          voice: selectedVoice,
          speed,
          model: "tts-1",
        }),

        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || t("failed-to-convert-text-to-speech")
        );
      }

      const audioBlob = await response.blob();
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);

      if (audioRef.current) {
        audioRef.current.src = url;
        audioRef.current.playbackRate = speed;
      }

      if (wavesurferRef.current) {
        wavesurferRef.current.loadBlob(audioBlob);
      }

      await audioRef.current?.play();
      setIsPlaying(true);
    } catch (err) {
      setError(err instanceof Error ? err.message : t("an-error-occurred"));
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlay = async () => {
    if (wavesurferRef.current) {
      if (isPlaying) {
        wavesurferRef.current.pause();
        setIsPlaying(false);
      } else if (wavesurferRef.current.getDuration() > 0) {
        wavesurferRef.current.play();
        setIsPlaying(true);
      } else {
        handleTextToSpeech();
      }
    }
  };

  const handleReset = (): void => {
    setText("");
    setSpeed(1);
    setSelectedVoice("zana");
    setIsPlaying(false);
    setAudioUrl(null);
    if (audioRef.current) {
      audioRef.current.src = "";
    }
    if (wavesurferRef.current) {
      wavesurferRef.current.empty();
    }
  };

  const handleSpeedChange = (_: Event, newValue: number | number[]): void => {
    const newSpeed = Array.isArray(newValue) ? newValue[0] : newValue;
    setSpeed(newSpeed);
    if (audioRef.current) {
      audioRef.current.playbackRate = newSpeed;
    }
    if (wavesurferRef.current) {
      wavesurferRef.current.setPlaybackRate(newSpeed);
    }
  };

  const handleVoiceChange = (event: SelectChangeEvent<string>): void => {
    setSelectedVoice(event.target.value as string);
  };

  const handleDownload = () => {
    if (audioUrl) {
      const a = document.createElement("a");
      a.href = audioUrl;
      a.download = `tts-${selectedVoice}-${new Date().getTime()}.mp3`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <>
      <StyledCard>
        <CardContent>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            align="center"
            sx={{ mb: 4 }}
          >
            {t("text-to-speech-0")}
          </Typography>

          <Stack spacing={4}>
            <TextField
              label={t("text-to-convert")}
              multiline
              rows={4}
              value={text}
              onChange={(e) => setText(e.target.value)}
              variant="outlined"
              fullWidth
              disabled={isLoading}
            />

            <FormControl fullWidth disabled={isLoading}>
              <InputLabel>Voice</InputLabel>
              <Select
                value={selectedVoice}
                label={t("voice")}
                onChange={handleVoiceChange}
              >
                {voices.map((voice) => (
                  <MenuItem key={voice.id} value={voice.id}>
                    {voice.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box>
              <Typography gutterBottom>Speed: {speed}x</Typography>
              <StyledSlider
                value={speed}
                min={0.5}
                max={2}
                step={0.1}
                onChange={handleSpeedChange}
                aria-labelledby="speed-slider"
                disabled={isLoading}
              />
            </Box>

            <WaveformContainer ref={waveformRef} />

            <Stack direction="row" spacing={2} justifyContent="center">
              <Button
                variant="contained"
                startIcon={
                  isLoading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : isPlaying ? (
                    <PauseIcon />
                  ) : (
                    <PlayArrowIcon />
                  )
                }
                onClick={handlePlay}
                disabled={isLoading && !isPlaying}
                sx={{
                  minWidth: 120,
                  bgcolor: isPlaying ? "error.main" : "primary.main",
                  "&:hover": {
                    bgcolor: isPlaying ? "error.dark" : "primary.dark",
                  },
                }}
              >
                {isLoading
                  ? t("converting")
                  : isPlaying
                  ? t("pause")
                  : t("play")}
              </Button>
              <Button
                variant="outlined"
                startIcon={<RestartAltIcon />}
                onClick={handleReset}
                sx={{ minWidth: 120 }}
                disabled={isLoading}
              >
                {t("reset")}
              </Button>
              <Button
                variant="outlined"
                startIcon={<DownloadIcon />}
                onClick={handleDownload}
                disabled={!audioUrl || isLoading}
                sx={{ minWidth: 120 }}
              >
                {t("download")}
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </StyledCard>

      <audio
        ref={audioRef}
        onEnded={() => setIsPlaying(false)}
        onPause={() => setIsPlaying(false)}
        onPlay={() => setIsPlaying(true)}
      />

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={() => setError(null)} severity="error" variant="filled">
          {error}
        </Alert>
      </Snackbar>
    </>
  );
}

import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";

import { createTheme } from "@mui/material/styles";
import MuiThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import useThemeStore from "./state/useThemeStore";

const getFontFamily = (lang: string) => {
  return lang === "ckb" ? "Rudaw, Arial" : "Roboto, sans-serif";
};

const createLightTheme = (lang: string) =>
  createTheme({
    typography: {
      fontFamily: getFontFamily(lang),
      fontSize: 14,
      fontWeightBold: 900,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
              @font-face {
                font-family: 'Rudaw';
                font-style: normal;
                font-display: swap;
                font-weight: 400;
                src: local('Rudaw'), local('Rudaw-Regular'), url('/fonts/NewRudaw-Regular.woff2') format('woff2');
              }
            `,
      },
    },
    palette: {
      mode: "light",
      primary: {
        main: "#FF5733",
      },
      secondary: {
        main: "#0173e6",
        light: "#0173e6",
        contrastText: "#47008F",
      },
      background: {
        default: "#f3f6fb",
      },
    },
  });

export const LIGHT_THEME = createLightTheme(document.documentElement.lang);

export const DARK_THEME = createTheme({
  typography: {
    fontFamily: "Rudaw, Arial",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
            @font-face {
              font-family: 'Rudaw';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('Rudaw'), local('Rudaw-Regular'), url('/fonts/NewRudaw-Regular.woff2') format('woff2');
            }
          `,
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#FF5733",
    },
    secondary: {
      main: "#E0C2FF",
      light: "#F5EBFF",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#47008F",
    },
  },
});

export default function ThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const theme = useThemeStore((store) => {
    const lang = document.documentElement.lang;
    return store.theme.palette.mode === "light"
      ? createLightTheme(lang)
      : DARK_THEME;
  });

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import ThemeProvider from "./ThemeProvider";
import useLocalizeDocumentAttributes from "./i18n/useLocalizeDocumentAttributes";
import { useTranslation } from "react-i18next";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false
        }
    }
});

export default function App() {
    const { i18n } = useTranslation();
    useLocalizeDocumentAttributes();
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider>
                <div className={i18n.language}>
                    <RouterProvider router={router} />
                </div>
            </ThemeProvider>
        </QueryClientProvider>
    );
}

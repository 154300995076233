import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import UploadIcon from "@mui/icons-material/Upload";
import MicIcon from "@mui/icons-material/Mic";
import EditNoteIcon from "@mui/icons-material/EditNote";
import RouterLink from "../../components/RouterLink";
import useUploadStore from "../../state/useUploadStore";
import useRecorderStore from "../../state/useRecorderStore";
import useTranscriptionFormStore from "../../state/useTranscriptionFormStore";
import { useTranslation } from "react-i18next";

export default function Guide() {
  const upload = useUploadStore((state) => state.handleOpenUpload);
  const record = useRecorderStore((state) => state.handleOpen);
  const transcribe = useTranscriptionFormStore((state) => state.handleOpen);
  const { t } = useTranslation();

  return (
    <Stack gap={2} mx="auto" maxWidth={850}>
      <Stack gap={2} direction="row" alignItems="center">
        <Stack
          width={36}
          height={36}
          fontWeight={500}
          borderRadius={20}
          fontSize="large"
          alignItems="center"
          justifyContent="center"
          bgcolor="primary.main"
          color="primary.contrastText"
        >
          1
        </Stack>
        <Typography fontSize="large">
          {t("start-by-uploading-a-recording-file")}
        </Typography>
      </Stack>
      <Stack gap={2} direction="row">
        <Box mx="18px" borderLeft={1} />
        <Stack py={2} gap={2} alignItems="start">
          <Typography>
            {t("to-get-started-upload-a-recording-in")} <b>{t("mp3")}</b>,{" "}
            <b>{t("wav")}</b>, {t("or")} <b>{t("ogg")}</b> {t("format")}
            {t(
              "alternatively-you-can-record-and-upload-audio-directly-using-the-built-in-recorder-to-access-your-recordings-click"
            )}{" "}
            <RouterLink text="here" href="/" />.
          </Typography>
          <Stack gap={2} direction="row" flexWrap="wrap">
            <Button onClick={upload} startIcon={<UploadIcon />}>
              {t("upload")}
            </Button>
            <Button onClick={record} startIcon={<MicIcon />}>
              {t("record")}
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Stack gap={2} direction="row" alignItems="center">
        <Stack
          width={36}
          height={36}
          fontWeight={500}
          borderRadius={20}
          fontSize="large"
          alignItems="center"
          justifyContent="center"
          bgcolor="primary.main"
          color="primary.contrastText"
        >
          2
        </Stack>
        <Typography fontSize="large">
          {t("transcribe-your-recording")}
        </Typography>
      </Stack>
      <Stack gap={2} direction="row">
        <Box mx="18px" borderLeft={1} />
        <Stack py={2} gap={2} alignItems="start">
          <Typography>
            {t(
              "click-below-to-begin-a-transcription-choose-a-file-and-configure-the-following-options"
            )}
          </Typography>
          <ul>
            <li>
              <b>{t("speaker-detection")}</b>:{" "}
              {t(
                "detect-speakers-in-your-recording-and-generate-a-conversational-transcript"
              )}
            </li>
            <li>
              <b>{t("email-notification-0")}</b>:{" "}
              {t("get-notified-when-your-transcription-is-complete")}
            </li>
            <li>
              <b>{t("improve-model-for-everyone")}</b>:{" "}
              {t("improve-the-quality-of-the-model-for-everyone")}
            </li>
            <li>
              <b>{t("remove-noise")}</b>:{" "}
              {t("remove-background-noise-from-your-recording")}
            </li>
            <li>
              <b>{t("spell-correction-just-for-central-kurdish")}</b>:{" "}
              {t("correct-misspelled-words")}
            </li>
          </ul>
          <Typography>
            {t("check-the-status-of-your-transcription")}{" "}
            <RouterLink text="here" href="/transcriptions" />.
          </Typography>
          <Button onClick={() => transcribe({})} startIcon={<EditNoteIcon />}>
            {t("start-a-new-transcription")}
          </Button>
        </Stack>
      </Stack>
      <Stack gap={2} direction="row" alignItems="center">
        <Stack
          width={36}
          height={36}
          fontWeight={500}
          borderRadius={20}
          fontSize="large"
          alignItems="center"
          justifyContent="center"
          bgcolor="primary.main"
          color="primary.contrastText"
        >
          3
        </Stack>
        <Typography fontSize="large">{t("manage-your-transcripts")}</Typography>
      </Stack>
      <Stack gap={2} direction="row">
        <Box mx="18px" borderLeft={1} />
        <Stack py={2} gap={2} alignItems="start">
          <Typography>
            {t("once-your-transcription-is-complete")}
            {t("go-to-the")}{" "}
            <RouterLink text="list view" href="/transcriptions" />{" "}
            {t(
              "and-click-on-the-view-symbol-you-can-export-your-transcript-as-a-text-or-json-file-or-as-a-csv-or-excel-file"
            )}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

import { useState } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useContactEmail } from "../../models/User";
import { useTranslation } from "react-i18next";

export default function FAQ() {
  const contactEmail = useContactEmail();
  const [expanded, setExpanded] = useState<string | null>(null);
  const { t } = useTranslation();

  const handleChange = (question: string, value: boolean) => {
    setExpanded(value ? question : null);
  };

  return (
    <Box>
      <Typography variant="h6" component="h2" textAlign="center" mb={4}>
        {t("frequently-asked-questions")}
      </Typography>
      <Accordion
        expanded={expanded === "questsion_1"}
        onChange={(_, value) => handleChange("questsion_1", value)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            color={expanded === "questsion_1" ? "primary.main" : "text.primary"}
          >
            {t("how-long-does-a-transcription-take")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {t(
              "transcribing-long-recordings-can-take-several-hours-depending-on-the-load-on-the-server-a-shorter-audio-or-deactivating-speaker-detection-can-speed-up-the-process"
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "questsion_2"}
        onChange={(_, value) => handleChange("questsion_2", value)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            color={expanded === "questsion_2" ? "primary.main" : "text.primary"}
          >
            {t(
              "what-should-i-do-if-a-transcription-is-stuck-or-not-processing-correctly"
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {t(
              "if-a-transcription-is-stuck-you-cant-restart-it-you-have-to-delete-it-and-create-a-new-one"
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "questsion_3"}
        onChange={(_, value) => handleChange("questsion_3", value)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            color={expanded === "questsion_3" ? "primary.main" : "text.primary"}
          >
            {t("my-transcription-doesnt-seem-to-be-progressing-what-can-i-do")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {t(
              "transcribing-long-recordings-can-take-a-long-time-the-system-also-has-a-limited-capacity-so-if-there-are-too-many-transcriptions-at-the-same-time-some-of-them-will-be-marked-as-pending-and-will-be-processed-when-the-load-on-the-server-decreases"
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "questsion_4"}
        onChange={(_, value) => handleChange("questsion_4", value)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            color={expanded === "questsion_4" ? "primary.main" : "text.primary"}
          >
            {t("why-did-my-transcription-fail")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {t(
              "sometimes-transcriptions-can-fail-due-to-technical-issues-if-this-happens-try-again-if-the-problem-persists-contact-the-administrator-at"
            )}
            <Link mt={2} display="block" href={`mailto:${contactEmail.data}`}>
              {contactEmail.data}
            </Link>
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion
                expanded={expanded === "questsion_5"}
                onChange={(_, value) => handleChange("questsion_5", value)}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography color={
                        expanded === "questsion_5" ?
                            "primary.main" :
                            "text.primary"
                    }>
                        {t('how-can-i-change-my-password')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {t('to-change-your-password-you-can-use-the-password-reset-functionality-on-the-login-page')}
                    </Typography>
                </AccordionDetails>
            </Accordion> */}
      <Typography textAlign="center" mt={4}>
        {t("for-help-feel-free-to-reach-out-to-the-administrator")}
        <Link mt={0.5} display="block" href={`mailto:${contactEmail.data}`}>
          {contactEmail.data}
        </Link>
      </Typography>
    </Box>
  );
}
